import React from "react";
import { StaticQuery, graphql } from "gatsby";

import SEO from "components/seo";
import Layout from "components/layout";
import Img from "gatsby-image";

export default () => (
    <StaticQuery
        query={graphql`
            query {
                dataJson(newstadium: {intro: {ne: null}}) {
                    newstadium {
                        intro
                        list {
                            image {
                                childImageSharp {
                                    fluid(maxWidth:702) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            caption
                        }
                    }
                }
            }
        `}
        render={data => (
            <Layout>
                <SEO
                    pageData={{
                        title: `The New Sydney Football Stadium`,
                        description: `The new Sydney Football Stadium is currently being built.`
                    }}
                />
                <div className="container-fluid">
                    <h1>New SFS Stadium</h1>

                    <div className="md:mx-auto mb-12 md:w-3/5">
                        <div className="mb-12" dangerouslySetInnerHTML={{ __html: data.dataJson.newstadium.intro }} />

                        <div className="">
                            {data.dataJson.newstadium.list.map((edge,index) => {
                                return (
                                    <div className="mb-12 text-center" key={index}>
                                        <Img className="mx-auto mb-3" fluid={edge.image.childImageSharp.fluid} alt={edge.caption } />
                                        <p className="" dangerouslySetInnerHTML={{ __html: edge.caption }} />
                                    </div>
                                )
                                })}
                        </div>
                    </div>
                    
                </div>
            </Layout>
        )}
    />
)
